/* nascondo le scrollbar */

html {
    scrollbar-width: none;
    /*For Firefox*/
    -ms-overflow-style: none;
    /*For IE and Edge*/
}

html::-webkit-scrollbar {
    width: 0;
    /*For Chrome, Safari and Opera*/
}

::-webkit-scrollbar {
    display: none;
}

.app-body {
    background-image: url("images/xforma-bg.jpg");
    background-size: cover;
}

.dashboard-body {
    /*
    display: flex;
    min-height: 100vh;
    flex-direction: column;
     */
}

table td,
table th {
    vertical-align: middle !important;
    border: 0 !important; //eliminazione bordi delle tabelle
}

.navbar-brand {
    align-items: center;
}


/* immagini grigie */

.is-grayscale {
    -webkit-filter: grayscale(1) blur(2px);
    filter: grayscale(1) blur(2px);
}

.is-transparent {
    background-color: transparent;
    color: $white;
}

.is-transparent-whitehover {
    background-color: transparent;
    color: $white;
}

.is-transparent-whitehover:hover {
    border-color: $white;
    background-color: $white;
    color: $dark;
}

.xforma-margin-bottom {
    margin-bottom: $xforma-gap;
}

.xforma-margin-bottom-2x {
    margin-bottom: 2*$xforma-gap;
}

.xforma-margin-top {
    margin-top: $xforma-gap;
}

.xforma-padding-gap {
    padding: $xforma-gap;
}

.xforma-btn-large {
    width: 40vw;
    border-width: 2px;
    padding: $xforma-gap;
}

.xforma-btn-large:hover {
    border-color: $red;
    color: $red;
}

.xforma-btn-large-white {
    width: 40vw;
    border-width: 0;
    padding: $xforma-gap;
    background-color: $white;
    color: $dark;
}

.xforma-btn-large-white:hover {
    border-color: $red;
    background-color: $red;
    color: $white;
}

.button-white {
    background-color: $white;
    color: $grey-dark;
    border: 0;
}

.button-white:hover,
.button-white:active {
    background-color: $red;
    color: $white;
}

.xforma-input {
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 1px solid $white;
    border-radius: unset;
    box-shadow: none;
}

.xforma-input::placeholder {
    color: hsl(0, 0%, 96%);
}

.xforma-input:focus {
    border: none;
    border-bottom: 1px solid $white;
    box-shadow: none;
}

.xforma-input-label {
    color: $white;
}

.xforma-input-app {
    background-color: transparent;
    color: $blue;
    border: none;
    border-bottom: 1px solid $grey-lighter;
    border-radius: unset;
    box-shadow: none;
}

.xforma-input-search {
    background-color: $xformabg;
    border: 0;
    box-shadow: none;
}

.xforma-input-search:focus,
.xforma-input-search:active {
    border: 0;
    box-shadow: none;
}

.xforma-btn-app {
    width: 50%;
    background-color: $orange;
    color: $white;
    border: 0;
    justify-content: center;
}

.xforma-btn-app-border {
    width: 50%;
    background-color: $white;
    color: $orange;
    border: 1px solid $orange;
    justify-content: center;
}

.xforma-btn-app:hover {
    color: $white;
    font-weight: 600;
}

.xforma-btn-app-white {
    width: 50%;
    background-color: $white;
    color: $orange;
    border-color: $orange;
    justify-content: center;
}

.xforma-btn-app-white-on-blu {
    @extend .xforma-btn-app;
    background-color: $white;
    color: $blue;
    border-color: $blue;
}

.xforma-btn-app-outline {
    @extend .xforma-btn-app;
    background-color: transparent;
    border-color: $white;
    border: 2px;
    border-style: solid;
}

.xforma-message {
    position: absolute;
    left: 50%;
    //height: 350px;
    max-height: 75vh;
    width: 500px;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 99999;
}

.xforma-message .message-body {
    max-height: 75vh;
    overflow-y: scroll;
    border-style: none !important;
}

.xforma-dashboard-msg-list a:not(.is-selected):nth-child(even) {
    background-color: $white-bis;
}

.xforma-dashboard {
    overflow-y: scroll;
    background-color: $xformabg;
    /*height: calc((100vh - 130px) - 3.75reme);*/
    height: calc(100vh - 14rem);
    padding-top: 0.75rem;
    padding-bottom: 1rem;
    // @media screen and (max-width: 576px) {
    //   height: 100vh;
    // }
}

.xforma-site-dashboard {
    background-color: $xformabg;
    padding-top: 0.75rem;
    height: 100vh;
}

.xforma-blue-gradient-bg {
    background-image: linear-gradient(to top right, $blue, $blue-light);
}

.navbar-profile-img {
    margin: auto !important;
    width: unset !important;
    max-height: unset !important;
}

nav {
    -webkit-box-shadow: $xforma-shadow;
    -moz-box-shadow: $xforma-shadow;
    box-shadow: $xforma-shadow;
    padding-top: calc(1rem / 2);
    padding-right: 4rem;
    padding-left: 3rem;
    padding-bottom: calc(1rem / 2);
}

.xforma-box-cantiere {
    -webkit-box-shadow: $xforma-shadow;
    -moz-box-shadow: $xforma-shadow;
    box-shadow: $xforma-shadow;
    height: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.xforma-box-cantiere img {
    width: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    margin-bottom: -0.5rem;
}

.xforma-box-cantiere-img {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.xforma-box-cantiere-progress progress {
    overflow: unset;
    height: 0.33rem;
}

.xforma-box-cantiere-text {
    padding: 0.5rem 0.75rem 0.2rem 0.75rem;
    text-overflow: ellipsis;
}

.xforma-box-cantiere-text:last-child {
    padding-top: 0.1rem;
}

.xforma-navbar-item {
    flex-direction: column;
    justify-content: center;
}

.xforma-navbar-item>p {
    color: $red;
}

.xforma-navbar-divider {
    padding-left: 0;
    padding-right: 0;
    border-left: 1px solid $black;
    height: 3.75rem;
}

.xforma-list-side-by-side {
    dt {
        float: left;
        margin-right: 10%;
        max-width: 40%;
        font-weight: bold;
    }
    dd {
        margin-left: 30%;
        margin-bottom: .5em;
        word-break: break-word;
    }
    dd::after {
        content: " ";
        display: block;
        clear: left;
    }
}

.xforma-list-side-by-side-track {
    dt {
        float: none;
        margin-right: 10%;
        max-width: none;
        font-weight: bold;
    }
    dd {
        margin-left: 0;
        margin-bottom: .5em;
        word-break: break-word;
    }
    dd::after {
        content: " ";
        display: block;
        clear: left;
    }
}

.xforma-list {
    dt {
        font-weight: bold;
    }
    dd {
        margin-bottom: .5em;
        word-break: break-word;
    }
    dd::after {
        content: " ";
        display: block;
        clear: left;
    }
}

.is-marginless-top {
    margin-top: 0 !important;
}

.is-marginless-right {
    margin-right: 0 !important;
}

.is-marginless-bottom {
    margin-bottom: 0 !important;
}

.is-marginless-left {
    margin-left: 0 !important;
}

.has-text-red {
    color: $red !important;
}

.has-text-orange {
    color: $orange !important;
}

.has-background-red {
    background-color: $red;
}

.has-padding-top {
    padding-top: 1.25rem !important;
}

.has-padding-bottom {
    padding-bottom: 1.25rem !important;
}

.has-text-weight-medium {
    font-weight: $weight-medium !important
}

.file-name {
    padding-left: 0;
}

.file-name .icon {
    color: $input-icon-color;
}

.xforma-modal-close:before {
    background-color: $dark;
}

.xforma-modal-close:after {
    background-color: $dark;
}

.xforma-modal-image {
    justify-content: center;
    margin: 0 0 2rem 0;
}


/** checkradio custom **/

.is-checkradio[type=radio]+label::before,
.is-checkradio[type=radio]:checked+label:before {
    border-width: thin;
}

.is-checkradio[type=radio]+label::after,
.is-checkradio[type=radio]+label:after {
    transform: scale(0.65);
}


/** checkradio custom **/

.xforma-vcentered {
    display: flex;
    align-content: center;
    align-items: center;
}

@for $i from 1 through 8 {
    .has-padding-lr-#{$i} {
        padding-left: #{$i}rem;
        padding-right: #{$i}rem;
    }
    .has-margin-px-#{$i} {
        margin: #{$i}px;
    }
}

.is-w40 {
    width: 40%;
}

.list-cover-image {
    #width: 228px;
    width: unset;
    height: 96px;
}

@media screen and (min-width: $fullhd) {
    .list-cover-image {
        #width: 228px;
        width: unset;
        max-height: 150px;
    }
}

.xforma-site-left-menu {
    height: 100vh;
    flex: 0 0 140px;
    //background-color: $blue;
    @extend .xforma-blue-gradient-bg;
}

.xforma-site-right-menu {
    height: 100vh;
    position: absolute;
    overflow-y: hidden;
    top: 0;
    right: 0;
    //flex: 0 0 280px;
    background-color: $white;
    width: 320px;
}

.xforma-site-right-menu nav {
    padding-right: 1rem;
    padding-left: 1rem;
}

.xforma-site-dashboard-footer {
    height: 160px;
}

.xforma-site-dashboard-footer .box {
    width: 100%;
}

.full-height-flex {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.box-less-padding {
    padding: $box-padding * 0.5;
}

.xforma-site-left-menu-item p {
    @extend .has-text-white,
    .is-size-6;
    line-height: 1rem;
    padding-bottom: 0.65rem;
}

.xforma-site-left-menu-item.is-active p {
    @extend .has-text-orange,
    .is-size-6,
    .has-text-weight-bold;
}

.xforma-site-tooltip::before {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}

.xforma-site-tooltip:hover::before,
.xforma-site-tooltip.is-tooltip-active::before {
    background-color: $white;
    color: $orange;
    font-size: 1rem;
    padding: 0.2rem 0.8rem;
}

.xforma-site-tooltip:focus:not(.is-loading)::after,
.xforma-site-tooltip:hover:not(.is-loading)::after,
.xforma-site-tooltip.is-tooltip-active:not(.is-loading)::after {
    border-color: $white transparent transparent transparent;
}

.is-paddingless-top {
    padding-top: 0
}

.is-paddingless-right {
    padding-right: 0
}

.is-paddingless-bottom {
    padding-bottom: 0
}

.is-paddingless-left {
    padding-left: 0
}

.is-paddingless-horizontal {
    padding-left: 0;
    padding-right: 0
}

.is-paddingless-vertical {
    padding-top: 0;
    padding-bottom: 0
}

.panel-block.is-orange-light,
.panel-block.is-orange-light:hover {
    color: $white;
    background-color: $orange-light;
}

.panel-block.is-orange,
.panel-block.is-orange:hover {
    color: $white;
    background-color: $orange;
}

.is-active.modal {
    z-index: 999999;
}

.xforma-invitation-item,
.xforma-slider-item {
    display: none;
}

.xforma-invitation-item.is-active,
.xforma-slider-item.is-active {
    display: flex;
}

.xforma-invitation-item:first-of-type,
.xforma-slider-item:first-of-type {
    display: flex !important;
}

.scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-5-28 17:18:2
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.main-box {
    @extend .is-paddingless;
    height: 100%;
    border: 1px solid transparent;
}


/*
.main-box.invoice-table{
  height: calc(100% - 70px);
  margin-bottom: 0.75rem;
}
.main-box.check-table{
  height: calc(100% - 70px);
  margin-bottom: 0.75rem;
}
*/

.main-box>div {
    height: 100%;
    overflow: scroll;
}

.main-box>.box {
    margin: 1px;
}

.site-bottom-navbar {
    left: unset !important;
    right: unset !important;
    display: unset;
    width: calc(100vw - 140px);
}

.xforma-site-page-title {
    margin-top: -1em;
}

.element {
    &.is-loading-xforma {
        position: relative;
        pointer-events: none;
        opacity: 0.8;
        &:after {
            animation: xFormaSpinAround 1200ms infinite;
            animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            //border-color: #ef5d2a;
            border-right-color: $red;
            border-top-color: $orange;
            border-left-color: $blue;
            border-bottom-color: $blue-light;
            border-style: double;
            border-width: 2rem;
            border-radius: 290486px;
            width: 10em;
            height: 10em;
            content: "";
            display: block;
            position: absolute;
            bottom: 50%;
            left: 50%;
            @media screen and (max-width: 480px) {
              left:30%;
            }
        }
    }
}

@keyframes xFormaSpinAround {
    from {
        transform: perspective(108px) rotateX(40deg) rotate(0deg);
    }
    to {
        transform: perspective(108px) rotateX(40deg) rotate(360deg);
    }
}

.element.is-loading:after {}

.element {
    &.is-loading-generic {
        position: relative;
        pointer-events: none;
        opacity: 0.5;
        &:after {
            @include loader;
            position: absolute;
            top: calc(50% - 2.5em);
            left: calc(50% - 2.5em);
            width: 5em;
            height: 5em;
            border-width: 0.25em;
        }
    }
}

.unset-width {
    width: unset;
}

.check-table,
.invoice-table,
.document-table,
.message-table {
    max-height: calc(100vh - 370px);
    overflow-y: scroll;
}

.tabs li.is-active a {
    border-bottom-width: 3px;
    border-bottom-style: solid;
}


/** datepicker **/

.datepicker-nav-month,
.datepicker-nav-year {
    text-decoration: underline !important;
}

.datetimepicker-clear-button {
    display: none;
}

.datetimepicker-dummy-input {
    max-width: 90% !important;
    word-spacing: unset !important;
}

.datetimepicker {
    z-index: 31;
}

.modal .datetimepicker {
    display: none;
    max-height: none;
    width: auto;
    max-width: 320px;
    overflow-y: visible;
    &.is-active {
        display: block;
    }
}


/** datepicker **/

div.legal-responsiblity {
    font-size: small;
}

.panel-block-messaggi {
    @extend .panel-block;
    //border-bottom: 1px solid $grey-light;
    border-left: 1px solid $grey-light;
    border-right: 1px solid $grey-light;
}

.is-hypenated {
    hyphens: auto;
}

.is-scrollable-x {
    overflow-x: scroll;
}

.control.checkbox {
    font-size: 12px;
    width: 100%;
    padding-left: 7px;
    border-bottom: 1px solid #dbdbdb;
    input[type="checkbox"] {
        height: 15px;
    }
    span {
        vertical-align: top;
    }
}

.tabbed-box {
    padding: 0;
    margin-top: 1em;
    div.tabs {
        border-bottom: 1px solid #CCCCCC;
    }
    nav.panel {
        margin-top: 40px;
    }
}

.tabbed-box.profile {
    padding: 1.25rem;
    div.tabs {
        margin-left: -1.25rem;
        margin-right: -1.25rem;
    }
}

table.table.invite {
    border-bottom: 1px solid #dbdbdb;
    td {
        vertical-align: middle;
        border: none;
    }
}

.invite-not-registered {
    .image {
        padding-top: 30px;
        padding-bottom: 30px;
        img {
            width: auto;
            height: 80px;
        }
    }
    .open-invite-modal-unregistered {
        width: 25%;
    }
}

.xforma-dashboard-main {
    width: 100%;
    // INIZIO MODIFICHE MTH
    // height: calc(100vh - 330px);
    @media screen and (max-width: 767px) {
      height: auto;
      margin-bottom: 100px;
    }
    // FINE MODIFICHE MTH
    .full-height {
        height: calc(100% - 30px);
        .gantt {
            // height: calc(100% - 68px);
            margin: 0;
            #item-0 {
                display: flex;
                // INIZIO MODIFICHE MTH
                @media screen and (max-width: 767px){
                  flex-direction: column;
                  overflow: scroll;
                  height: 100%;
                }
                // FINE MODIFICHE MTH
                &>div {
                    flex: 1 0 50%;
                }
                // INIZIO MODIFICHE MTH
                .gantt-controls{
                  @media screen and (max-width: 767px){
                    width: 100%;
                    text-align: center;
                  }
                }
                .fc-button-group {
                  @media screen and (max-width: 767px) {
                    margin:0;
                  }
                }
            }
            #item-1 {
              @media screen and (max-width: 767px){
                flex-direction: column;
                overflow: scroll;
                height: 100%;
              }
              @media screen and (min-width: 1280px){
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 100%;
              }
              .gantt-bottom {
                @media screen and (max-width: 767px){
                  flex-direction: column;
                  margin-top: 50px;
                }
                ul {
                  @media screen and (max-width: 767px){
                    flex-direction: column;
                  }
                }
              }
            }
            // FINE MODIFICHE MTH
        }
    }
}

.xforma-fab {
    padding: 1px 6px;
    text-align: center;
    align-items: flex-start;
    vertical-align: middle;
    border: none;
    border-radius: 50%;
    width: 70px !important;
    height: 70px !important;
    font-size: 25px;
    cursor: pointer;
    background: transparent;
    display: inline-block;
}

.xforma-fab img {
    display: inline;
}

#gantt {
  height:100%;
}

.modalgant,.modalgant-2 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width:40%;
  margin:auto;
  position:absolute;
  left:50%;top:50%;
  transform:translate(-50% , -50% );
  height: 40%;
  background: white;
  border-radius: 20px;
  text-align: center;
  z-index: 9999;
}
.modalsite,.modalsite-chiudi {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width:50%;
  margin:auto;
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50% , -50% );
  height: auto;
  padding: 30px;
  background: white;
  border-radius: 10px;
  text-align: center;
  z-index: 9999;
}

@media screen and (max-width:478px) {
  .modalsite,.modalsite-chiudi {
    width: 100%;
  }
}
.gantt-bottom {
    display: flex;
    align-items: center;
    bottom: 0;
    .gantt-controls {}
    .gantt-legend {
        flex: 1;
        ul {
            display: flex;
            li {
                color: #d8d8d8;
                font-size: 15px;
                margin-right: 15px;
                @media screen and (min-width: 1024px) {
                    font-size: 12px;
                }
            }
            li:before {
                content: " ";
                width: 13px;
                height: 13px;
                border: 1px solid black;
                display: inline-block;
                margin-right: 5px;
            }
            li.planned:before {
                background-color: #d8d8d8;
                border: none;
            }
            li.early:before {
                background-color: #fff;
                border: 3px solid #d8d8d8;
            }
            li.ontime:before {
                background-color: #88a5b3;
                border: none;
            }
            li.late:before {
                background-color: #f6a588;
                border: none;
            }
        }
    }
}

// INIZIO MODIFICHE MTH
@media screen and (min-width: 768px) and (max-width: 966px) {
  .gantt-bottom {
    flex-wrap: wrap;
    justify-content: center;
    .gantt-legend {
      margin:20px 0;
    }
  }
}

.modal-close {
  right: 0;
}

#gantt_elastic {
  max-height: 300px;
  overflow: scroll;
  @media screen and (max-width: 767px) {
   max-height: 450px;
  }
  @media screen and (min-width: 1280px) {
   height: 100%;
   max-height: none;
  }
}

#left_menu {
  display: flex;
  overflow: scroll;
  @media screen and (max-width: 767px){
   display: none;
   height: 100%;
  }
}

#figure_gantt {
  @media screen and (min-width: 1280px) {
    height: 500px;
    margin-top: 20px;
  }
}
#showpage_responsive {
  height: calc(100vh - 240px);
  overflow: scroll;
  padding-bottom: 120px;
  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.chevron-mobile-left,.chevron-mobile-right {
  display: none;
}

@media screen and (max-width: 576px) {
  #footer_scroll > div {
    display: none;
  }
  #footer_scroll > div.mobile-active {
    display: block;
  }
  .chevron-mobile-left,.chevron-mobile-right {
    display: block;
  }
}
// .mobile-active {
//   display: block;
// }
#mobile_dashboard {
  width: calc( 100vw - 140px);
  height: calc(100vh - 100px);
  overflow: scroll;
  display: flex;
  flex-flow: column nowrap;
  @media screen and (max-width: 767px){
   width: 100%;
   // height: 100vh;
   display: block;
  }
  @media screen and (min-width: 1280px){
   width: 100%;
   // height: 100%;
   display: block;
  }
}

// @media screen and (min-width: 768px) and (max-width: 966px) {
//   #footer_box {
//     flex-direction: column;
//     align-items: center;
//
//   }
// }

// #footer_scroll>div{
//   width: 400px;
// }

// @media screen and (max-width:767px) {
//
//   #banner {
//     display: none;
//   }
// }
#banner {
  height:100px;
}
@media screen and (max-width:576px) {
  #banner {
    height: 50px;
  }
}

.sub_right_box {
  width: auto;
}

#open_mobile_left_menu {
  display: none;
  font-size: 45px;
  color:$orange-light;
  // position: absolute;
  // right: 20px;
  @media screen and (max-width: 767px){
    display: block;
    margin-left: 30px;
  }
}

#closer_mobile {
  display: none;
  @media screen and (max-width: 767px){
   display: block;
   font-size: 45px;
   color:#fff;
   position: absolute;
   right: 20px;
   top:25px;
  }
}

#dashboard_site {
  overflow: auto;
  @media screen and (max-width:767px) {
    overflow: auto;
  }
}

.mobile_hid {
  @media screen and (max-width:767px) {
    display: none;
  }
}

// #nav_top {
//   @media screen and (max-width: 992px) {
//     display: flex;
//     overflow: scroll;
//     padding-left:0;
//     padding-right:0;
//     & > *{
//       margin:0 20px;
//     }
//     .level-right,.level-left{
//       display: flex;
//       margin: 0 15px;
//       .level-item {
//         margin:0 15px;
//       }
//     }
//   }
//    @media screen and (max-width: 576px) {
//      justify-content: center;
//      & > * {
//       font-size:18px;
//       margin:0;
//     }
//     padding-top:20px;
//
//    }
// }

@media screen and (max-width:992px) {
  #nav_top {
    display: flex;
    overflow: scroll;
    padding-left:0;
    padding-right:0;
    & > *{
      margin:0 20px;
    }
    .level-right,.level-left{
      display: flex;
      // margin: 0 15px;
      .level-item {
        margin:0 15px;
      }
    }
  }
}

@media screen and (min-width:576px) {
  .invite_icon {
    display: none;
  }
}

#mobile_user_cantiere {
  margin:0 0 0 32px;
}

@media screen and (max-width:576px) {
  #nav_top {
    justify-content: center;
    & > * {
      font-size:18px;
      margin:0;
    }
    .level-right {
      margin-left: 20px;
    }
    padding-top:20px;
  }
  #invite_btn {
    display: none;
  }
  #mobile_user_cantiere {
    margin:0;
  }
}

// CAROUSEL
.container-test,.container-test-verbale {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
  background: black;
  }
  .slider-wrapper {
  position: relative;
  margin: auto;
  width: 100%;
  height: 80%;
  }
  .slider-wrapper .images {
  height: 100%;
  text-align: center;
  }
  .slider-wrapper .images img {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 70%;
  margin: auto;
  max-height:100%;
  }
  .slider-wrapper .images img.active {
  display: inline-block;
  }
  .prev, .next {
  position: absolute;
  color: #565a5c;
  top: 50%;
  /* left: 0; */
  transform: translateY(-50%);
  font-size: 40px;
  cursor: pointer;
  }
  .next {
  left: auto;
  right: 10%;
  }
  .prev {
    left:10%;
  }

  #carousel-close,#carousel-close-verbale  {
    position: absolute;
    right: 5%;
    top: 5%;
    font-size: 30px;
    z-index: 9999;
  }

@media screen and(max-width:769px) {

  .powered-by {
    font-size: 11px;
  }
  #btn-share {
    width: auto;
  }
 .report-table-files  {
   display: flex;
   align-items: flex-start;
   flex-direction: column;
   margin-bottom: 10px;
 }
}

@media screen and(max-width:578px) {

  .powered-by {
    font-size: 8px;
  }
}

@media screen and (max-width: 576px) {
  .mobile_user_box {
    display: flex;
    flex-direction: column;
  }
  #mobile_docs_list {
    flex-direction: column;
  }
  .docs_link {
    word-break: break-all;
  }
  #mobile_report_text {
    float:left;
  }
  #report_mobile {
    display: flex;
    justify-content: space-around;
    .level-item {
      margin:0;
    }
  }
  #invoice_mobile_value {
    justify-content: flex-start;
  }
  #report_mobile_task {
    display: flex;
    justify-content: flex-start;
    .level-item {
      margin:0 20px 0 0;
    }
  }
  .tr_mobile_report {
    border-bottom:1px solid lightgrey;
  }
}

#gantt {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 335px;
  }
}

#footer_scroll {
  // @media screen and (min-width: 768px) and (max-width: 966px) {
  //  flex-wrap: nowrap;
  //  overflow: scroll;
  //  justify-content: end;
  // }
  // @media screen and (max-width: 767px) {
  display: flex;
  width: 100%;
  // overflow: scroll;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin:0;
  // }

  #footer_box  {
    @media screen and (max-width: 767px) {
      width:100%;
    }
  }
  // @media screen and (max-width: 576px) {
  //   overflow: scroll;
  // }
  div {
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}


  @media screen and (max-width: 576px) {
    .navbar-menu {
      box-shadow: none;
      display: none;
    }
    #navbarBasicExample {
      display: flex;
      align-items: center;
    }
    .navbar-end {
      display: none;
    }
    .navbar-head {
      display: flex;
      justify-content: space-around;
    }
    .message_icon {
      position: absolute;
      top: -10px;
      background: $blue-light;
      border-radius: 50%;
      padding: 3px 5px;
      left: -6px;
    }
    .navbar-menu2 {
      display: block;
      margin-top:20px;
    }
    #icon {
      margin:0;
    }
    #paper-plane-mobile {
      width: 80%;
      position: relative;
      margin:0;
      padding: 75%;
    }
  }

  #paper-plane-mobile {
    @media screen and (min-width: 576px) {
      width: auto;
    }
  }
  @media screen and (min-width: 576px) {
    #paper-plane-mobile {
      width: auto;
    }
    .navbar-menu {
      display: flex;
      justify-content: center;
    }
    .navbar-menu2 {
      display: none;
    }
  }

  .text_messaggi {
    display: block;
    font-size: 18px;
    font-weight: bold;
    margin-left: 5px;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }


#nav_mobile {
  // width: 100%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.fc-toolbar.fc-header-toolbar {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

// FINE MODIFICHE MTH


/** data badge **/

[data-badge].has-badge-site-bar::after {
    transform: translate(25%, -25%);
    ;
    background-color: $white;
    box-shadow: unset;
    color: $grey-dark;
}

.buttons {
    justify-content: center;
}

.button {
    justify-content: center;
}

#edit-gantt-task-modal .buttons>button,
#notify-gantt-modal .buttons>button {
    width: 45%;
}


/** note **/

.open-notes {
    line-height: 4rem;
    font-size: x-large;
    color: $grey;
    padding-right: 1rem;
}


/** modal buttons **/

.xforma-no-btn-modal {
    @extend .xforma-btn-app;
    width: 10vw;
    margin-left: 1rem;
    margin-right: 1rem;
}

.xforma-yes-btn-modal {
    @extend .xforma-btn-app-white;
    width: 10vw;
    margin-left: 1rem;
    margin-right: 1rem;
}

.carousel-bullets {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    .carousel-indicators {
        text-align: center;
        list-style: none;
        li {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin: 1px;
            text-indent: -999px;
            cursor: pointer;
            background-color: #000 \9;
            background-color: rgba(0, 0, 0, 0);
            border: none;
            border-radius: 10px;
            background-color: #d9d9d9;
        }
        .active {
            width: 12px;
            height: 12px;
            margin: 0;
            background-color: #22333b;
        }
    }
}

.fc table td:not([align]),
.fc table th:not([align]) {
    text-align: center;
}

#gauges #time-schedule {
  height: 250px;
}

#gauges #completion {
    width: 50%;
    height: 250px;
    margin: auto;
}

#edit-gantt-task-modal {
    .modal-content.edt {
        // margin-right: 0;
    }
    .box {
        border-top-left-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 6px;
    }
}

.confirmed-notice {
    text-align: center;
    font-size: smaller;
    color: #f38159;
    font-weight: bold;
}

.drag-over {
    border: 1px dashed #f38159;
}

.report-table-action {
    // float: right;
    overflow-wrap: break-word;
}

.report-table-files {
  display: flex;
    justify-content: space-between;
    align-items: center;
}

.fc-toolbar h2 {
    @media screen and (min-width: 1024px) {
        font-size: 20px;
    }
}

.fc-event-container {
    display: none;
}

.fc-event-milestone {
    background-color: #f38159;
    color: #FFF;
    padding: 5px;
    border-radius: 50%;
    a {
        color: #FFF;
    }
}

td.fc-day-top {
    padding: 5px;
}

.ch-validation {
    border: none;
    position: relative;
    .ch-validation-bubble {
        position: absolute;
        top: 0;
        left: 100%;
        width: 25%;
        font-size: smaller;
        display: none;
    }
}

.ch-validation.active {
    border-top: 1px dashed #AA0000;
    border-bottom: 1px dashed #AA0000;
    .ch-validation-bubble {
        position: absolute;
        top: 0;
        left: 100%;
        width: 25%;
        font-size: smaller;
        display: block;
    }
}

.pagok {
    color: green;
}

.pagko {
    color: red;
}

.label-project-style {
    font-weight: bold;
}

.flex-left {
    display: flex;
}

.field {
    color: black;
}

.padding-5 {
    padding: 5px !important;
}

.avanzamento {
    width: 70%;
    display: inline-block;
}

.left-10 {
    margin-left: 10%;
}

.block-inline-center {
    display: inline-flex;
    align-items: center;
}

.height-auto {
    height: auto;
}

.col-three {
    width: 33%;
}

.button-add-footer {
    justify-content: center;
    display: flex;
}

.modal-card,
.modal-content {
    max-height: calc(100vh - 160px);
    overflow: auto;
}

#edit-gantt-task-project-modal .modal-content {
    height: 600px;
}
