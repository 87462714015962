@import "../global";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600');

// Bootstrap Overrides
@import 'variables';
//Bulma
@import "~bulma/bulma";
@import "~bulma-extensions/bulma-switch/src/sass/index";
@import "~bulma-calendar/src/sass/index";
@import "~bulma-checkradio/src/sass/index";
@import "~bulma-divider/src/sass/index";
@import "~bulma-tooltip/src/sass/index.sass";
@import "~bulma-o-steps/bulma-steps";
@import "~bulma-tooltip/src/sass/index";
@import "~bulma-badge/src/sass/index";

//@import "~bulma-extensions/bulma-carousel/src/sass/index";
@import url('https://unpkg.com/@fullcalendar/core@4.3.1/main.min.css'); 

// Pages
@import 'pages/dashboard';
// main css
@import "xforma";